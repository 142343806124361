.Sprite {
  position: absolute;
  display: block;
  background: url(../resources/arcade_pacman_sprites.png);
  transform-origin: top left;
  /* Propagate mouse clicks and hover down to the underlying grid*/
  pointer-events: none;
}

.Sprite-ghost {
  width: 16px;
  height: 16px;
}

/* Ghost 0 */

.Sprite-ghost-0-direction-RIGHT-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-RIGHT-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-LEFT-phase-0 {
  background-position: calc(-1px * (456 + 2 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-LEFT-phase-1 {
  background-position: calc(-1px * (456 + 3 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-UP-phase-0 {
  background-position: calc(-1px * (456 + 4 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-UP-phase-1 {
  background-position: calc(-1px * (456 + 5 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-DOWN-phase-0 {
  background-position: calc(-1px * (456 + 6 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-ghost-0-direction-DOWN-phase-1 {
  background-position: calc(-1px * (456 + 7 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-frightened-ghost-color-blue-phase-0 {
  background-position: calc(-1px * (456 + 8 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-frightened-ghost-color-blue-phase-1 {
  background-position: calc(-1px * (456 + 9 * 16)) calc(-1px * (64 + 0 * 16));
}

/* Ghost 1 */

.Sprite-ghost-1-direction-RIGHT-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-RIGHT-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-LEFT-phase-0 {
  background-position: calc(-1px * (456 + 2 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-LEFT-phase-1 {
  background-position: calc(-1px * (456 + 3 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-UP-phase-0 {
  background-position: calc(-1px * (456 + 4 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-UP-phase-1 {
  background-position: calc(-1px * (456 + 5 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-DOWN-phase-0 {
  background-position: calc(-1px * (456 + 6 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-ghost-1-direction-DOWN-phase-1 {
  background-position: calc(-1px * (456 + 7 * 16)) calc(-1px * (64 + 1 * 16));
}

/* Ghost 2 */

.Sprite-ghost-2-direction-RIGHT-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-RIGHT-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-LEFT-phase-0 {
  background-position: calc(-1px * (456 + 2 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-LEFT-phase-1 {
  background-position: calc(-1px * (456 + 3 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-UP-phase-0 {
  background-position: calc(-1px * (456 + 4 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-UP-phase-1 {
  background-position: calc(-1px * (456 + 5 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-DOWN-phase-0 {
  background-position: calc(-1px * (456 + 6 * 16)) calc(-1px * (64 + 2 * 16));
}

.Sprite-ghost-2-direction-DOWN-phase-1 {
  background-position: calc(-1px * (456 + 7 * 16)) calc(-1px * (64 + 2 * 16));
}

/* Ghost 3 */

.Sprite-ghost-3-direction-RIGHT-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-RIGHT-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-LEFT-phase-0 {
  background-position: calc(-1px * (456 + 2 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-LEFT-phase-1 {
  background-position: calc(-1px * (456 + 3 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-UP-phase-0 {
  background-position: calc(-1px * (456 + 4 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-UP-phase-1 {
  background-position: calc(-1px * (456 + 5 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-DOWN-phase-0 {
  background-position: calc(-1px * (456 + 6 * 16)) calc(-1px * (64 + 3 * 16));
}

.Sprite-ghost-3-direction-DOWN-phase-1 {
  background-position: calc(-1px * (456 + 7 * 16)) calc(-1px * (64 + 3 * 16));
}

/* Frightened ghost */

.Sprite-frightened-ghost-time-0-phase-0 {
  background-position: calc(-1px * (584 + 0 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-frightened-ghost-time-0-phase-1 {
  background-position: calc(-1px * (584 + 1 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-frightened-ghost-time-1-phase-0 {
  background-position: calc(-1px * (584 + 2 * 16)) calc(-1px * (64 + 0 * 16));
}

.Sprite-frightened-ghost-time-1-phase-1 {
  background-position: calc(-1px * (584 + 3 * 16)) calc(-1px * (64 + 0 * 16));
}

/* Dead ghost */

.Sprite-dead-ghost-direction-RIGHT {
  background-position: calc(-1px * (584 + 0 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-dead-ghost-direction-LEFT {
  background-position: calc(-1px * (584 + 1 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-dead-ghost-direction-UP {
  background-position: calc(-1px * (584 + 2 * 16)) calc(-1px * (64 + 1 * 16));
}

.Sprite-dead-ghost-direction-DOWN {
  background-position: calc(-1px * (584 + 3 * 16)) calc(-1px * (64 + 1 * 16));
}

/* PacMan */

.Sprite-pacman {
  width: 16px;
  height: 16px;
}

.Sprite-pacman-direction-RIGHT-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * (0));
}

.Sprite-pacman-direction-RIGHT-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * (0));
}

.Sprite-pacman-direction-RIGHT-phase-2,
.Sprite-pacman-direction-LEFT-phase-2,
.Sprite-pacman-direction-UP-phase-2,
.Sprite-pacman-direction-DOWN-phase-2 {
  background-position: calc(-1px * (456 + 2 * 16)) calc(-1px * (0));
}

.Sprite-pacman-direction-LEFT-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * (1 * 16));
}

.Sprite-pacman-direction-LEFT-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * (1 * 16));
}

.Sprite-pacman-direction-UP-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * 2 * 16);
}

.Sprite-pacman-direction-UP-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * 2 * 16);
}

.Sprite-pacman-direction-DOWN-phase-0 {
  background-position: calc(-1px * (456 + 0 * 16)) calc(-1px * 3 * 16);
}

.Sprite-pacman-direction-DOWN-phase-1 {
  background-position: calc(-1px * (456 + 1 * 16)) calc(-1px * 3 * 16);
}

.Sprite-dying-pacman {
  width: 16px;
  height: 16px;
}

.Sprite-dying-pacman-phase-0 {
  background-position: calc(-1px * (504 - 1 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-1 {
  background-position: calc(-1px * (504 + 0 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-2 {
  background-position: calc(-1px * (504 + 1 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-3 {
  background-position: calc(-1px * (504 + 2 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-4 {
  background-position: calc(-1px * (504 + 3 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-5 {
  background-position: calc(-1px * (504 + 4 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-6 {
  background-position: calc(-1px * (504 + 5 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-7 {
  background-position: calc(-1px * (504 + 6 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-8 {
  background-position: calc(-1px * (504 + 7 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-9 {
  background-position: calc(-1px * (504 + 8 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-10 {
  background-position: calc(-1px * (504 + 9 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-11 {
  background-position: calc(-1px * (504 + 10 * 16)) calc(-1px * (0));
}
.Sprite-dying-pacman-phase-12 {
  background-position: calc(-1px * (504 + 10 * 16)) calc(-1px * (16));
}

.Sprite-maze {
  width: calc(14 * 16px);
  height: calc(15.5 * 16px);
}

.Sprite-maze-state-full {
  background-position: calc(-1px * (0)) calc(-1px * 0);
}

.Sprite-maze-state-empty {
  background-position: calc(-1px * (4 + (14 * 16))) calc(-1px * 0);
}

.Sprite-basic-pill {
  width: calc(8px);
  height: calc(8px);
  background-position: calc(-1px * (1 * 8)) calc(-1px * 1 * 8);
}

.Sprite-energizer {
  width: calc(8px);
  height: calc(8px);
  background-position: calc(-1px * (1 * 8)) calc(-1px * 3 * 8);
}
